import { useState, useEffect, useCallback } from 'react'
import { CheckoutTenderGiftCard } from '@open-tender/types'
import { selectCheckout, useAppSelector } from '@open-tender/cloud'
import { Button, Checkmark, Input } from 'components'
import {
  CheckoutValutecCardApplied,
  CheckoutValutecCardButton,
  CheckoutValutecCardInputs,
  CheckoutValutecCardMessage,
  CheckoutValutecCardView,
} from './CheckoutValutec.styled'
import { formatDollars } from '@open-tender/utils'

const CheckoutValutecAppliedCard = ({
  removeGiftCard,
  applied,
}: {
  removeGiftCard: (cardNumber: string) => void
  applied: CheckoutTenderGiftCard
}) => {
  const [submitting, setSubmitting] = useState(false)
  const { balance, card_number, pin, amount } = applied
  const { loading } = useAppSelector(selectCheckout)

  useEffect(() => {
    if (loading === 'idle' && submitting) setSubmitting(false)
  }, [loading, submitting])

  const remove = useCallback(() => {
    setSubmitting(true)
    removeGiftCard(card_number)
  }, [removeGiftCard, card_number])

  return (
    <>
      <CheckoutValutecCardView>
        <CheckoutValutecCardInputs>
          <Input
            type="text"
            name="card_number"
            label="Gift Card Number"
            value={card_number}
            disabled
            style={{ margin: 0 }}
          />
          <Input
            type="text"
            name="pin"
            label="PIN"
            value={pin}
            disabled
            style={{ margin: 0 }}
          />
        </CheckoutValutecCardInputs>
        <CheckoutValutecCardButton>
          <Button
            onClick={remove}
            disabled={submitting}
            size="small"
            color="secondary"
          >
            {submitting ? 'Remove...' : 'Remove'}
          </Button>
        </CheckoutValutecCardButton>
      </CheckoutValutecCardView>
      <CheckoutValutecCardApplied>
        <Checkmark />
        <CheckoutValutecCardMessage>
          {formatDollars(amount)} of gift card balance{' '}
          {formatDollars(balance as any)} applied to order.
        </CheckoutValutecCardMessage>
      </CheckoutValutecCardApplied>
    </>
  )
}

export default CheckoutValutecAppliedCard
